.card {
  perspective: 1000px;
}

.card__inner.active {
  transform: rotateY(180deg);
}

.card__inner {
  transition: transform 1.2s;
  transform-style: preserve-3d;
}

.card__front,
.card__back {
  backface-visibility: hidden;
}

.card__back {
  transform: rotateY(180deg);
}
